.product-details {
    h4 {
       color: #333;
       padding-bottom: 15px;
       border-bottom: 1px solid #e8e8e8;
       
       @media screen and (max-width: 767px) {
           font-size: 18px;
       }
    }

    .category-brand {
       display: flex;
       margin-bottom: 50px;
       
       @media screen and (max-width: 767px) {
         margin-bottom: 15px;
       }

       div {
          display: flex;
          align-items: center; 
          margin-right: 25px;

          span {
             color: #333;
             font-size: 16px;
       
             @media screen and (max-width: 767px) {
                 font-size: 14px;
             }

             &:first-child {
                font-size: 14px;
                color: #999;
                margin-right: 4px;
       
                @media screen and (max-width: 767px) {
                    font-size: 12px;
                }
             }
          }
       }
    }
    .product-details-id {
       display: flex;
       align-items: center; 
       margin-bottom: 50px;
       
       @media screen and (max-width: 767px) {
         margin-bottom: 15px;
       }

       span {
         font-size: 16px;
         color: #666 !important;
         margin-right: 6px;
       
         @media screen and (max-width: 767px) {
             font-size: 14px;
         }
       }

       a {
         text-decoration: none;
       }

       .article-number-details {
         color: #337ab7 !important;
         font-size: 20px;
         font-weight: 600;

         @media screen and (max-width: 991px) {
            font-size: 16px;
         }

       }
    }

    .product-price-details {
       display: flex;
       align-items: baseline;
 
       h2 {
          font-weight: 700;
          color: #333;
          font-size: 34px;
          margin-right: 10px;
       
          @media screen and (max-width: 767px) {
              font-size: 24px;
          }
       }
 
       span {
          font-size: 20px;
          text-decoration: line-through;
          color: #999;
          font-weight: 600;
       }
    }

    .order-btn-product {
       display: flex;
       justify-content: start;
       
       @media screen and (max-width: 767px) {
           justify-content: center;
       }
    }
 }

 .product-details-cart {
    h4 {
        font-size: 24px;
        padding-bottom: 10px;
       
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }

    .category-brand { 
        margin-bottom: 3px;
    }

    .product-details-id {
        margin-bottom: 5px;

        span {
            @media screen and (max-width: 767px) {
               font-size: 12px;
            }
        }
    }

    .product-price-details {
      h2 {
         font-size: 25px;
       
         @media screen and (max-width: 767px) {
             font-size: 20px;
         }
      }

      span {
         font-size: 16px;
      }
    }
 }