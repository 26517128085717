.default-btn {
    width: 100%;
    max-width: 350px;
    color: #111010;
    padding: 9px 20px 9px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    border: 1px solid #111010;
    background-color: #111010;
    color: #fff;
    transition: background-color 0.25s ease;
    font-size: 12px;
    margin-top: 10px;
    align-self: center;
    letter-spacing: 0.7px;
    border-radius: 2px;
                  
    @media screen and (max-width: 575px) {
        padding: 4px 8px 4px;
        font-size: 10px;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    &:hover {
        background-color: #fff;
        border-color: #e0e0e0;
        color: #111010;

        a {
            color: #111010;
        }
    }
}