.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 155;

  .spinner-border {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -25px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
  }
}

.show-in {
  visibility: visible;
  opacity: 1;
  transition: opacity .2s linear;
}

.show-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .2s, opacity .2s linear;
}