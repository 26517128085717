.content {
    // padding-top: 124px; in case of discount bar
    padding-top: 86px; 
    min-height: 97vh;
                  
    @media screen and (max-width: 575px) {
        // padding-top: 153px; in case of discount bar
        padding-top: 91px;
    }

}

.content-subheading {
    // padding-top: 148px; in case of discount bar
    padding-top: 128px;
                  
    @media screen and (max-width: 575px) {
        // padding-top: 178px; in case of discount bar
        padding-top: 138px;
    }
}    

body {
    font-family: 'Montserrat' !important;
}

.box-shadow-hover {
    transition: 0.3s;

    &:hover {
        box-shadow: 0 0 3px rgba(100, 100, 100, .3);
    }
}

.discount-container {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #eb0238;
    color: #fff;
    font-size: 13px;
    padding: 3px 10px;
    z-index: 5;
    font-weight: 500;
    border-radius: 2px;
}

.no-hover {
    background-color: #111010 !important;
    border-color: #111010 !important;
    color: #fff !important;
}

.form-control {
    border-radius: 3px !important;
}