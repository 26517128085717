.product-price {
  text-align: center;

  .sub-text {
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .price {
      font-size: 16px;
      color: #333;
      font-weight: 700;
      margin: 0 8px;
            
      @media screen and (max-width: 575px) {
          font-size: 10px;
          margin: 0 6px;
      }
  }

  .discount-price {
      font-size: 14px;
      font-weight: 700;
      color: #ccc;
      text-decoration: line-through;
            
      @media screen and (max-width: 575px) {
          font-size: 8px;
      }
  }
}

.product-price-big {
    .price {
        font-size: 26px;
    }

    .discount-price {
        font-size: 20px;
    }
}