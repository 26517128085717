.order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.7px;
    line-height: 23px;

    svg {
        width: 23px;
        height: 23px;
        
        path {
            fill: #fff;
        }
    }

    &:hover {
        svg {
            path {
                fill: #333;
            }
        }
    }
}

.btn-clicked {
    font-size: 16px;
}