.discount-bar {
    background: #d60000;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.2);
    padding: 10px;

    h3 {
        text-transform: uppercase;
        font-size: 13px;
        margin-bottom: 0;
        font-weight: bold;
        color: white;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }

        @media screen and (max-width: 500px) {
            font-size: 11px;
        }
    }
 }