.filters-guide-btn {
    background-color: #2981CA;
    width: 55px !important;
    height: 55px;
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 11;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;

    .filters-count {
        position: absolute;
        top: -6px;
        right: -3px;
        width: 20px;
        height: 20px;
        background-color: #111010;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 800;
        color: white;
    }


    img {
        width: 33px;
    }
}