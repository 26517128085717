.cart-item {
    border-radius: 2px;
    max-height: 100%;
    padding: 15px;
    background-color: #F8F8F8;
    border: 1px solid #e8e8e8;

    @media screen and (max-width: 767px) {
        padding: 10px;
    }

    .product-img {
        display: flex;
        justify-content: center;
        border-radius: 2px;

        img {
            max-width: 100%;
            max-height: 200px;
            display: block;
            object-fit: contain;
            border-radius: 2px;
        }
    }

    .default-btn {
        margin: auto;
       
        @media screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }
}