.product-page {
   .image-gallery-thumbnail-image {
      border-radius: 2px;
      object-fit: contain;
   }

   .image-gallery-thumbnail {
      border: 4px solid transparent;
      border-radius: 2px;
   }

   .image-gallery-slide {
      border: 1px solid #e8e8e8;
      border-radius: 2px;
      padding: 5px;
   }  

   .image-gallery-image {
      border-radius: 2px;
   }

   .discount-container {
      right: 25px;
   }

   .cart-btn {
      display: flex;
      justify-content: center;
      margin-top: 25px;
   }

   .product-phone-redirect {
      display: flex;
      flex-direction: column;
      text-align: center;

      h5 {
         margin-top: 15px;
      }
   }
}