.header {
    background: #fff;
    // border-bottom: 1px solid #e5e5e5;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 25;
    padding: 0 !important;
  
    @media screen and (max-width: 767px) {
        text-align: center;
    }

    nav {
        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            float: right;

            @media screen and (max-width: 767px) {
                justify-content: center;
                float: none;
            }

            .cart-nav-item {
                position: relative;

                @media screen and (max-width: 767px) {
                    position: absolute;
                    right: 15px;
                    // top: 77px; in case of <DiscountBar/>
                    top: 58px;
                }

                span {
                    position: absolute;
                    top: -11px;
                    right: -8px;
                    background-color: red;
                    padding: 1px 6px;
                    border-radius: 50%;
                    color: white;
                    font-weight: 600;
                    font-size: 13px;
                }
            }

            li {
                display: flex;
                align-items: center;
                margin: 0 7px;
                  
                @media screen and (max-width:767px) {
                    margin: 0 8px;
                }
                  
                @media screen and (max-width:460px) {
                    margin: 0 5px;
                }
                  
                @media screen and (max-width:390px) {
                    margin: 0 4px;
                }
                  
                @media screen and (max-width:365px) {
                    margin: 0 3px;
                }

                a {
                    color: #333;
                    text-decoration: none;
                  
                    @media screen and (max-width: 460px) {
                        font-size: 14px;
                    }
                  
                    @media screen and (max-width: 390px) {
                        font-size: 13px;
                    }
                  
                    @media screen and (max-width: 365px) {
                        font-size: 12px;
                    }
                

                    &:hover {
                        color: #000;
                        -moz-transition: all .2s ease-in;
                        -o-transition: all .2s ease-in;
                        -webkit-transition: all .2s ease-in;
                        transition: all .2s ease-in;
                    }
                }
            }
        }
    }
}
  
.logo-container {
    display: flex;
    justify-content: start;
    align-items: center;

    img {
        width: 90px;
        margin-right: 5px;
    }
    
    @media screen and (max-width: 991px) {
        img {
            width: 70px;
        }
    }
    
    @media screen and (max-width: 767px) {
        justify-content: center;
    }

    .logo {
        font-size: 30px;
        cursor: pointer;
        color: #333;
        text-decoration: none;
    
        @media screen and (max-width: 767px) {
            font-size: 25px;
        }
    
        @media screen and (max-width: 991px) {
            font-size: 20px;
        }

        &:hover {
            color: #000;
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        }
    }
}

.rfm-marquee-container  {
    background-color: #ff6f6f;
}