.additional-discount {
    text-transform: uppercase;
    text-align: center;
    color: #d60000;
    font-size: 24px;
}

.completed-order {
    svg {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
        color: #07bc0c;
    }
}