.last-added-products {
    padding-top: 50px;
    padding-bottom: 12px;

    h3 {
        text-align: center;
    }

    .slide-col {
        padding: 0 12px;
    }

    .slick-slider {
        margin-bottom: 60px;

        .slick-arrow {
            position: absolute;
            top: calc(50% - 28px);
            display: flex !important;
            align-items: center;
            z-index: 15;
            justify-content: center;
            width: 54px;
            height: 38px;
            background-color: #111010;
            box-shadow: 0px 12px 16px -8px rgba(76, 85, 102, .24);
            border-radius: 4px;
            transition: background-color 0.25s ease;
            border: 1px solid #111010;
            cursor: pointer;
            color: #fff;

            &:hover, &:focus {
                color: #111010;      
                outline: none;
                background-color: #fff;
                border-color: #111010;

                svg {
                    width: 11px;
                    height: 14px;
                    color: #111010;
                }
            }

            svg {
                width: 11px;
                height: 14px;
                color: #fff;
            }

            &::before {
                content: none;
            }
        }
    }
}   