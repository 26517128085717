.footer {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 40px;
   background: #fff;
   border-top: 1px solid #e5e5e5;
   box-shadow: 0px 0px 4px rgba(100, 100, 100, 0.2);
 
   div {
     color: #333;
     font-size: 16px;
 
     span {
       font-size: 25px;
     }

     @media screen and (max-width: 767px) {
         font-size: 14px;
 
         span {
           font-size: 19px;
         }
     }
   }
 }
 