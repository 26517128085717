.contact-info {
    text-align: center;
    
    img {
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
    }

    a {
        text-decoration: none;
        color: #212529;
    }
}
 