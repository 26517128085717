.product-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    padding: 12px;
    border: 1px solid #e8e8e8; 
    border-radius: 2px;

    .line-separator {
        display: flex;
        justify-content: center;
        margin: 5px 0;
        span {
            display: block;
            width: 50%;
            border-bottom: 1px solid #e8e8e8; 
        } 
    }

    .product-img {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        cursor: pointer;
        border-radius: 2px;

        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            object-fit: contain;
            border-radius: 2px;
        }
    }

    a {
        text-decoration: none;
    }

    .product-title {
        text-align: center;
        
        p {
            cursor: pointer;
            font-size: 14px;
            margin: 0;
            color: #333;
            height: 42px;
                  
            @media screen and (max-width: 575px) {
                font-size: 11px;
                line-height: 12px;
                height: 25px;
            }
        }
    }

    .product-double-btn {
        display: flex;

        .btn-clicked {
            a {
                font-size: 15px;

                @media screen and (max-width: 1200px) {
                     font-size: 10px;
                }
            }
        }

        button {
            height: 45px;

            &:first-child {
               margin-right: 1px;
               padding-right: 5px;
               padding-left: 5px;

               @media screen and (max-width: 1200px) {
                    font-size: 10px;
               }
                  
               @media screen and (max-width: 767px) {
                   padding-right: 3px;
                   padding-left: 3px;
                   font-size: 8px;
                   height: 33px;
                   font-size: 7px;

                   svg {
                       width: 15px;
                       height: 15px;
                   }
               }
                  
               @media screen and (max-width: 363px) {
                font-size: 6px;
               }
            }
        }

        .cart-btn-icon {
            margin-left: 1px;
            width: 20%;
            padding: 0;

            svg {
                width: 20px;
                height: 19px;
            }
                  
            @media screen and (max-width: 767px) {
                height: 33px;

                svg {
                    width: 15px;
                    height: 14px;
                }
            }
        }
    }
}

