.leaflet-wrapper {
    padding: 11px 15px !important;
    border: 1px solid #e8e8e8;
    border-radius: 2px;

    h5 {
        margin-bottom: 7px;
        font-size: 16px;
    }

    // .leaflet-container {
    //     cursor: pointer;
    //     height: 50vh;
    //     width: 100wh;
    //     border-radius: 2px;
    // }

    img {
        width: 100%;
        max-width: 100%;
    }
}
