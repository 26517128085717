.product-filter {
    padding: 20px;
    background-color: #f8f8f8;
    margin-bottom: 10px;

    h4 {
        color: #333;
        padding-bottom: 15px;
        font-size: 14px;
        font-weight: 700;
        border-bottom: 1px solid #e8e8e8;

        @media screen and (max-width: 767px) {
            font-size: 12px;
        }

        @media screen and (max-width: 991px) {
            font-size: 13px;
        }
    }

    .default-btn {
        max-width: 100%;
        width: 100%;
    }

    .filter-checkbox {
        cursor: pointer;
        
        span {
            font-size: 13px;

            @media screen and (max-width: 991px) {
                font-size: 10px;
            }
        }

        .form-check-input {
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-right: 10px;

            @media screen and (max-width: 991px) {
                width: 15px;
                height: 15px;
                margin-top: 6px;
                margin-right: 6px;
            }
        }
    }
}

.clear-filters {
    width: 200px;
    height: 36px;
    font-size: 12px;
}