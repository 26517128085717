.cart-form {
    padding-top: 50px;
    padding-bottom: 50px;

    .required-input {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #d60000;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(#d60000, 0.25);
    }
}