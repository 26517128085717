.shoe-type-filter {
    button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

            img {
                width: 40px;
                height: 40px;
            }

            @media screen and (max-width: 991px) {
                font-size: 13px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
    }

    p {
        margin-bottom: 7px;
        text-align: center;
    }
  
}