.MuiPagination-root {
    float: right;

    @media screen and (max-width: 767px) {
        float: none;
    }

    .MuiButtonBase-root {
        font-family: 'Montserrat';
        color: #0d6efd;
        font-size: 13px;
        border-color: #e8e8e8;

        @media screen and (max-width: 767px) {
            min-width: 28px;
            height: 28px;
        }
    }

    .Mui-selected {
        background-color: #0d6efd !important;
        border-color: #0d6efd ;
        color: #fff;
    }
}