.sub-heading-text {
    background: #ff6f6f;
    // box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.2);
    padding: 7px;

    h3 {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
        color: white;
        margin-right: 500px;

        @media screen and (max-width: 767px) {
            margin-right: 100px;
            font-size: 11px;
        }

        @media screen and (max-width: 500px) {
            margin-right: 70px;
            font-size: 10px;
        }
    }
}