.price-range-filter {
  .price-range {
    font-size: 13px;

    .price-range-filter-dash {
      margin: 0 5px;
    }
  }

  .base-btn {
    margin-top: 0;
  }

  .MuiSlider-thumb {
    width: 13px;
    height: 13px;
  }
}