.filters-btn {
    background-color: #2981CA !important;
    border-color: #2981CA !important;
    color: #fff !important;
    font-size: 12px;
}

.show-hide-btn {
    button {
        max-width: 100%;
    }
}
