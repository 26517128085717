.categories-section {
    margin-bottom: 60px;
    margin-top: 50px;
    text-align: center;
    
    a {
        text-decoration: none;
    }
    
    .categories-item-wrapper {
        padding: 15px;
        border: 1px solid #e8e8e8;
        border-radius: 2px;

        .categories-item {
            background-size: cover;
            padding: 100px 0;
            border-radius: 2px;

            @media screen and (max-width: 768px) {
                padding: 50px 0;
            }
    
            img {
                width: 70px;
            }
            
            p {
                font-size: 25px;
                background-color: rgba(250, 250, 250, 0.85);
                padding: 7px;
                text-transform: uppercase;
                font-weight: 800;
                color: #090708;

                @media screen and (max-width: 1200px) {
                    font-size: 20px;
                }

                @media screen and (max-width: 991px) {
                    font-size: 14px;
                }

                @media screen and (max-width: 767px) {
                    font-size: 18px;
                }
            }
        }
    }
}
 