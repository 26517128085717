.hero-section {
    display: flex;
    align-items: center;
    background-size: cover;
    min-height: 540px;
    background-position: center;
    box-shadow: 0 0 15px rgba(100, 100, 100, 0.4);
    
    .hero-banner {
        position: relative;
        left: 16vw;
        padding: 32px;
        max-width: 45vw;
        border-radius: 8px;
        background-color: rgba(255,255,255, 0.97);
        box-shadow: 0 0 30px rgba(100, 100, 100, 0.8);    
        border-radius: 2px;

        @media screen and (max-width: 767px) {
            left: 3vw;
            max-width: 94vw;
        }

        h1 {
            font-size: 24px;
            text-transform: uppercase;
            margin: 0;
        }
        
        p {
            font-size: 22px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}